'use client';
import Image from 'next/image';
import Link from 'next/link';
import { useState, useEffect } from 'react';
import cx from 'classnames';
import styles from './index.module.css';
import { usePathname } from 'next/navigation';

export default function Menu() {
  const LIST_ITEMS = [
    {
      href: '/news',
      label: '新着情報',
    },
    {
      href: '/company',
      label: '会社情報',
    },
    {
      href: '/business',
      label: '事業内容',
    },
    {
      href: '/blog',
      label: 'テックブログ',
    },
    {
      href: '/recruit',
      label: '採用情報',
    },
    {
      href: '/contact',
      label: 'お問い合わせ',
    },
  ];
  const [colorChange, setColorChange] = useState(false);

  useEffect(() => {
    const changeHeaderColor = () => {
      if (window.scrollY >= 370) {
        setColorChange(true);
      } else {
        setColorChange(false);
      }
    };
    // スクロールイベントを追加
    window.addEventListener('scroll', changeHeaderColor);

    // コンポーネントのアンマウント時にイベントを削除
    return () => {
      window.removeEventListener('scroll', changeHeaderColor);
    };
  }, []);
  const [isOpen, setOpen] = useState<boolean>(false);
  const router = usePathname();
  const open = () => setOpen(true);
  const close = () => setOpen(false);
  return (
    <div>
      <nav className={cx(styles.nav, isOpen && styles.open)}>
        <ul className={styles.items}>
          {LIST_ITEMS.map((item) => {
            const isMenuHere = item.href === '/' ? router === '/' : router.includes(item.href);
            const classNames = [
              styles.item,
              isMenuHere ? styles.current : '', // 現在のメニュー項目であれば 'current' スタイルを適用
              colorChange ? styles.colorChange : '', // スクロールによる色変更があれば 'colorChange' スタイルを適用
            ]
              .join(' ')
              .trim(); // 空白で結合して、先頭または末尾の余分な空白を削除

            return (
              <li key={item.label} className={classNames}>
                <Link href={item.href} onClick={close}>
                  {item.label}
                </Link>
              </li>
            );
          })}
        </ul>
        <button className={cx(styles.button, styles.close)} onClick={close}>
          <Image src="/close.svg" alt="閉じる" width={24} height={24} priority />
        </button>
      </nav>
      {!isOpen && (
        <button className={styles.button} onClick={open}>
          <Image
            src={colorChange ? '/menuReverse.svg' : '/menu.svg'}
            alt="メニュー"
            width={24}
            height={24}
            priority
            color="red"
          />
        </button>
      )}
    </div>
  );
}
