'use client';
import { useState, useEffect } from 'react';
import Menu from './Menu';
import Image from 'next/image';
import Link from 'next/link';
import styles from './index.module.css';

export default function Header() {
  const [colorChange, setColorChange] = useState(false);

  useEffect(() => {
    const changeHeaderColor = () => {
      if (window.scrollY >= 370) {
        setColorChange(true);
      } else {
        setColorChange(false);
      }
    };

    // スクロールイベントを追加
    window.addEventListener('scroll', changeHeaderColor);

    // コンポーネントのアンマウント時にイベントを削除
    return () => {
      window.removeEventListener('scroll', changeHeaderColor);
    };
  }, []);

  const logoLink = colorChange ? '/fullstackLogoColor.svg' : '/fullstackLogoMono.svg';
  return (
    <header
      className={`${styles.header}
       ${colorChange ? styles.colorChange : ''}`}
    >
      <Link href="/" className={styles.logoLink}>
        <Image
          src={logoLink}
          alt="フルスタック株式会社"
          className={styles.logo}
          width={348}
          height={133}
          priority
        />
      </Link>
      <Menu />
    </header>
  );
}
